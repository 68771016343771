<template>
  <v-card class="m-5" min-width="180" max-width="180" :elevation="2">
    <v-img :src="getFileUrl(userNode.avatar)" aspect-ratio="1" class="transition-ease-in-out" @error="hasError=true">
      <template v-slot:placeholder>
        <v-row no-gutters class="primary fill-height ma-0" align="center" justify="center">
          <span v-if="hasError || _.isEmpty(userNode.avatar)" class="name-initials-size white--text headline">
            {{ userNode.initials }}
          </span>
        </v-row>
      </template>
    </v-img>
    <v-card-title @click.stop="$router.push({ name:'UserPublicProfile', params: { uuid: userNode.id }})">
      <v-hover v-slot:default="{ hover }">
        <span :class="hover ? 'deep-orange--text lighten-1 h4' : 'h4'">{{ userNode.name }}</span>
      </v-hover>
    </v-card-title>
    <v-card-subtitle class="overline" v-text="userNode.jobTitle"/>
  </v-card>
</template>

<script>

import {User} from 'models/user';
export default {
  name: 'Node',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      userNode: {},
      hasError: false
    };
  },
  created() {
    this.userNode = new User(this.data);
  }
};
</script>

<style scoped>
.name-initials-size {
  font-size: 80px !important
}
</style>
