<template>
  <v-container fluid class="px-lg-5">
    <app-header :title="title" :breadcrumbs="breadcrumbs" />
    <v-card class="fill-height" flat outlined v-if="isLoaded">
      <app-tree v-for="(tree, index) in organizationChart" :ref="`scaleTree`" :key="index"
        class="fill-height" :dataset="tree" link-style="straight">
        <template v-slot:node="{ node }">
          <tree-node :data="node" @click="drawer = true"/>
        </template>
      </app-tree>
      <v-footer color="rgba(0, 0, 0, 0.1)" absolute class="font-weight-medium" padless>
        <v-col class="text-center" cols="12">
          <v-btn v-for="(btn, index) in scalingButtons" :key="index" class="mx-2" fab x-small
            @click="handleScaleChange(btn.action)">
            <v-icon dark v-if="btn.icon">{{ btn.icon }}</v-icon>
            <span v-if="btn.text">{{ btn.text }}</span>
          </v-btn>
        </v-col>
      </v-footer>
    </v-card>
  </v-container>
</template>

<script>
import TreeNode from 'components/users/TreeNode';
import {mapActions, mapGetters} from 'vuex';

export default {
  components: {
    TreeNode
  },
  data() {
    return {
      title: {
        text: this.$t('layout.mainMenu.organizationChart'),
        icon: 'mdi-sitemap'
      },
      breadcrumbs: [{
        text: this.$t('layout.mainMenu.home'),
        to: {name: 'Home'}
      },
      {
        text: this.$t('layout.mainMenu.organizationChart'),
        to: {name: 'OrganizationChart'},
        exact: true
      }],
      isLoaded: false,
      drawer: false,
      scalingButtons: [
        {
          action: 'zoomIn',
          icon: 'mdi-plus'
        },
        {
          action: 'zoomOut',
          icon: 'mdi-minus'
        },
        {
          action: 'restore',
          text: '1:1'
        }
      ]
    };
  },
  computed: mapGetters(['organizationChart']),
  methods: {
    ...mapActions(['getOrganizationChart']),
    handleScaleChange(command) {
      switch (command) {
      case 'zoomIn':
        this.$refs.scaleTree.forEach((tree) => tree.zoomIn());
        break;
      case 'zoomOut':
        this.$refs.scaleTree.forEach((tree) => tree.zoomOut());
        break;
      case 'restore':
        this.$refs.scaleTree.forEach((tree) => tree.restoreScale());
        break;
      }
    }
  },
  created() {
    this.getOrganizationChart().then(() => {
      this.isLoaded = true;
    });
  }
};
</script>
